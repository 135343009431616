<template>
  <div class="un">
    <img style="width: 400px; height: 350px"
      src="../assets/images/500.png" alt="">

  <div class="msg">
    服务器正在抢修中,请稍后重试
  </div>
  
  </div>
</template>
<script>
export default {
  name:'500',
  mounted(){
     setTimeout(() => {
      this.$router.push("/login")
  
    }, 3000);
  },

  
  methods:{
    onLogin(){
      location.reload();
    }
  }
}
</script>
<style lang="less" scoped>
.un{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .msg{
    font-size: 26px;
    margin-top: 20px;
    a{
      color: #409EFF;
    }
  }
}
</style>